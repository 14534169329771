<!--
// nuxt-ui/components/Shared/CardStoreAd.vue
-->
<script setup lang="ts">
import ContextLogoCrown from "~/nuxt-ui/components/context-shared/ContextLogoCrown.vue";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";

type Props = {
  titleCard: string;
  productImage?: string | undefined;
  productTitle: string | undefined;
  productLink: string | undefined;
  productLinkTarget?: string;
  closable?: boolean;
  small?: boolean;
  light?: boolean;
  withTitle?: boolean;
  withButton?: boolean;
};

withDefaults(defineProps<Props>(), {
  light: false,
  withTitle: false,
  withButton: true,
});

const { isKings } = useWebContext();
const { isSpain } = useRegions();

const isClosed = ref<boolean>(false);
</script>

<template>
  <div
    v-if="!isClosed && productImage"
    :class="[
      'flex flex-col rounded-lg  h-full overflow-hidden',
      small ? 'min-h-[298px] w-[181px]' : 'min-h-[568px] w-full',
    ]"
  >
    <div class="container-title" v-if="withTitle">
      <context-logo-crown v-if="!small" width="18"></context-logo-crown>
      <p :class="[small ? 'text-[10px]' : 'text-xs', 'title-official-store']">{{ titleCard }}</p>
      <context-logo-crown v-if="!small" width="18"></context-logo-crown>
      <div
        v-if="closable"
        :class="[small ? 'right-1' : 'right-3', 'absolute cursor-pointer']"
        @click="isClosed = !isClosed"
      >
        <svg
          class="w-5 h-5 text-gray-300"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="black"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m13 7-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
      </div>
    </div>
    <div
      class="flex flex-col justify-between items-center px-4 pb-4 bg-cover bg-top bg-no-repeat"
      :class="[small ? 'min-h-[18rem] h-[298px]' : 'min-h-[calc(100%-49px)] h-[568px]']"
      :style="`background-image: url('${productImage}')`"
    >
      <div class="flex flex-col items-center justify-center w-full mt-auto">
        <p :class="[light ? 'text-white' : 'text-black', 'product-name']">{{ productTitle }}</p>
        <a
          v-if="productLink"
          :href="productLink"
          :target="productLinkTarget || '_blank'"
          :class="[
            small ? 'text-sm' : 'text-base',
            light ? 'bg-white text-black border-none hover:bg-black hover:text-white' : 'btn-secondary',
            'btn w-full text-center font-medium',
          ]"
          >{{ $t("card.store.buyButton") }}</a
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
.container-title {
  @apply flex gap-4 py-2 items-center justify-center bg-gradient-to-l from-primary-dark to-black w-full relative;
}

.title-official-store {
  @apply text-center uppercase font-bold font-['Archivo_Expanded'] text-primary-light;
}

.product-name {
  @apply text-sm mb-2 text-center px-2;
}
</style>
